<template>
  <div :id="id" :class="customClass"></div>
</template>

<script>
import * as d3 from "d3";
import $ from "jquery";
import { sleep } from "@/utils/utils.js";
export default {
  props: ["id", "customClass"],
  data() {
    return {
      svg: null,
      svgLineTotalLength: 0,
      svgLineTotalArr: [],
    };
  },
  methods: {
    async playOut() {
      this.svg
        .selectAll(`.domain,.tick_line,.tick_long_line,.line_slope,.x_label,.y_label,.tip_box`)
        .transition()
        .duration(300)
        .style("opacity", "0");
      this.svg
        .selectAll(`.text,.dot,.now_group,.ave_group,.area_group,.line`)
        .transition()
        .duration(300)
        .delay((d, i) => i * 30)
        .ease(d3.easeCircleOut)
        .style("opacity", "0");
    },
    async init() {
      await this.getSettings();
    },
    getSettings() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          resolve();
        });
      });
    },
    //核心库
    async EvaluatingChart(
      data,
      {
        label = (d) => d.name, // given d in data, returns the (ordinal) x-value
        y = (d) => d.value, // given d in data, returns the (ordinal) x-value
        marginTop = 40, // the top margin, in pixels
        marginRight = 50, // the right margin, in pixels
        marginBottom = 30, // the bottom margin, in pixels
        marginLeft = 40, // the left margin, in pixels
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        xDomain, // an array of (ordinal) x-values
        xRange = [marginLeft, width - marginRight], // [left, right]
        yDomain, // [ymin, ymax]
        yRange = [height - marginBottom, marginTop], // [bottom, top]
        yLabel = "次数",
        duration = 400, //动画持续时长
        ease = "easeQuadOut", //元素之间间隔时长
        xTickNumber = 2,
        yTickNumber = 7,
        rectWidth = 106,
        rectHeight = 28,
      } = {}
    ) {
      const X = d3.map(data, label);
      const Y = d3.map(data, y);

      if (xDomain === undefined) xDomain = [d3.min(X), d3.max(X)];
      if (yDomain === undefined) yDomain = [0, d3.max(Y)];

      const xScale = d3.scaleSequential(xDomain, xRange);
      const yScale = d3.scaleLinear(yDomain, yRange);

      //获取x轴的tickValues
      const getXtickValues = () => {
        const xArr = [];
        if (xTickNumber == 1) {
          let index = parseInt(data.length / 2);
          xArr.push(xScale.domain()[index]);
        } else if (xTickNumber == 2) {
          xArr.push(xScale.domain()[0]);
          xArr.push(xScale.domain()[1]);
        } else if (xTickNumber > 2) {
          for (let i = 0; i < xTickNumber; i++) {
            let index = Math.ceil(i * ((data.length - 1) / (xTickNumber - 1)));
            index = index > data.length - 1 ? data.length - 1 : index;
            xArr.push(xScale.domain()[index]);
          }
        }
        return xArr;
      };

      const xAxis = d3.axisBottom(xScale).tickValues(getXtickValues()).tickSizeOuter(0);
      const yAxis = d3.axisLeft(yScale).ticks(yTickNumber).tickSizeOuter(0);

      const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      this.svg = svg;

      //yLabel
      svg
        .append("text")
        .attr("class", "y_label")
        .attr("fill", "currentColor")
        .attr("x", xScale(X[0]))
        .attr("y", marginTop)
        .attr("dy", "-0.9em")
        .attr("text-anchor", "end")
        .attr("opacity", 0)
        .text(yLabel)
        .transition()
        .delay(300)
        .duration(300)
        .attr("opacity", 1);

      //y轴坐标轴
      const axisY = svg
        .append("g")
        .attr("class", "axis_y")
        .attr("transform", `translate(${marginLeft},0)`)
        .call(yAxis)
        .call((g) => {
          g.select(".domain").attr("opacity", 0);
          g.selectAll(".tick line").attr("class", "tick_line").attr("opacity", 0);
          g.selectAll(".tick text").attr("class", "text").attr("opacity", 0);
        });
      axisY.selectAll(".domain").transition().duration(600).attr("opacity", 1);
      axisY.selectAll(".tick_line").transition().duration(600).attr("opacity", 1);
      axisY
        .selectAll(".text")
        .transition()
        .delay((d, i) => i * 100)
        .ease(d3.easeCircleOut)
        .duration(600)
        .attr("opacity", 1);

      //x轴坐标轴
      let xRealTick;
      const axisX = svg
        .append("g")
        .attr("class", "axis_x")
        .attr("transform", `translate(0,${height - marginBottom})`)
        .call(xAxis)
        .call((g) => {
          g.select(".domain").attr("opacity", 0);
          g.selectAll(".tick line").attr("class", "tick_line").attr("opacity", 0);
          g.selectAll(".tick text").attr("class", "text").attr("opacity", 0);
          xRealTick = g.selectAll(".tick")._groups[0].length;
        });

      axisX.selectAll(".domain").transition().duration(600).attr("opacity", 1);
      axisX.selectAll(".tick_line").transition().duration(600).attr("opacity", 1);
      axisX
        .selectAll(".text")
        .transition()
        .delay((d, i) => i * 100)
        .ease(d3.easeCircleOut)
        .duration(600)
        .attr("opacity", 1)
        .attr("text-anchor", (d, i) => {
          if (i == 0) {
            return "start";
          } else if (i == xRealTick - 1) {
            return "end";
          } else {
            return "middle";
          }
        });

      //画线
      const drawLine = () => {
        //数据的序号数组
        const I = d3.range(X.length);
        const pathLine = d3
          .line()
          .curve(d3.curveLinear)
          .x((d, i) => xScale(X[i]))
          .y((d, i) => yScale(Y[i]));
        const svgLine = svg
          .append("path")
          .attr("fill", "none")
          .attr("class", `line`)
          .attr("stroke", "black")
          .attr("d", pathLine(I));
        svgLine.attr("opacity", 0).transition().duration(duration).attr("opacity", 1);

        // const svgLineTotalLength = svgLine.node().getTotalLength();
        // svgLine
        //   .attr("stroke-dasharray", svgLineTotalLength + "," + svgLineTotalLength)
        //   .attr("stroke-dashoffset", svgLineTotalLength)
        //   .transition()
        //   .duration(600)
        //   .attr("stroke-dashoffset", 0);
      };
      drawLine();

      //画线下边的面积
      const drawArea = () => {
        const area = d3
          .area()
          .x((d, i) => xScale(X[i]))
          .y0(height - marginBottom)
          .y1((d, i) => yScale(Y[i]));

        //画渐变
        const areaGroup = svg.append("g").attr("class", "area_group");
        const defs = areaGroup.append("defs");
        const linearGradient = defs
          .append("linearGradient")
          .attr("id", "linearColor")
          .attr("x1", "0%")
          .attr("y1", "0%")
          .attr("x2", "0%")
          .attr("y2", "100%");
        linearGradient.append("stop").attr("class", "area_top").attr("offset", "0%");
        linearGradient.append("stop").attr("class", "area_bottom").attr("offset", "100%");

        //画区域图
        const areaI = d3.range(data[0].fNowIndex + 1);
        areaGroup
          .append("path")
          .attr("d", area(areaI))
          .attr("class", "line_area")
          .attr("opacity", "0")
          .attr("fill", "url(#" + linearGradient.attr("id") + ")")
          .transition()
          .duration(400)
          .delay(duration * 0.6)
          .ease(d3[ease])
          .attr("opacity", "1");
      };

      //画均值
      const drawAveGroup = () => {
        const positionY = marginTop - 22;
        const avgIndex = data[0].fAvgIndex;
        const aveGroup = svg.append("g").attr("class", "ave_group");
        aveGroup
          .append("line")
          .attr("class", "eva_ave_line")
          .attr("x1", xScale(X[avgIndex]))
          .attr("x2", xScale(X[avgIndex]))
          .attr("y1", height - marginBottom)
          .attr("y2", height - marginBottom)
          .transition()
          .duration(300)
          .delay(duration * 0.6)
          .attr("y1", positionY);
        aveGroup
          .append("rect")
          .attr("class", "eva_ave_rect")
          .attr("x", xScale(X[avgIndex]) - rectWidth / 2)
          .attr("y", positionY - rectHeight)
          .attr("rx", 8)
          .attr("ry", 8)
          .attr("width", rectWidth)
          .attr("height", rectHeight)
          .attr("opacity", "0")
          .transition()
          .duration(300)
          .delay(duration * 0.6 + 120)
          .attr("opacity", "1");
        aveGroup
          .append("text")
          .attr("x", xScale(X[avgIndex]))
          .attr("y", positionY - rectHeight / 2)
          .attr("dy", "0.4em")
          .attr("class", "eva_ave_txt")
          .attr("text-anchor", "middle")
          .html("历史均值" + X[avgIndex])
          .attr("opacity", "0")
          .transition()
          .duration(300)
          .delay(duration * 0.6 + 240)
          .attr("opacity", "1");
      };

      //画当前值
      const drawCurrentGroup = () => {
        const positionY = 85;
        const nowIndex = data[0].fNowIndex;
        const mode = data[0].mode;
        const nowGroup = svg.append("g").attr("class", "now_group");
        const currentLine = nowGroup
          .append("line")
          .attr("class", "eva_current_line")
          .attr("x1", xScale(X[nowIndex]))
          .attr("x2", xScale(X[nowIndex]))
          .attr("y1", height - marginBottom)
          .attr("y2", height - marginBottom)
          .transition()
          .duration(300)
          .delay(duration * 0.6)
          .attr("y1", positionY);
        const currentDot = nowGroup
          .append("circle")
          .attr("cx", xScale(X[nowIndex]))
          .attr("cy", positionY)
          .attr("r", "8")
          .attr("class", "eva_current_dot")
          .attr("opacity", "0")
          .transition()
          .duration(300)
          .delay(duration * 0.6)
          .attr("opacity", "1");
        const halfIndex = Math.floor(data.length / 2);
        const currentHtml1 = nowGroup
          .append("text")
          .attr("x", function () {
            if (nowIndex < halfIndex) {
              return xScale(X[nowIndex]) + 8;
            } else {
              return xScale(X[nowIndex]) - 8;
            }
          })
          .attr("y", positionY)
          .attr("class", "eva_current_text")
          .attr("text-anchor", function (d) {
            if (nowIndex < halfIndex) {
              return "start";
            } else {
              return "end";
            }
          })
          .html('<tspan class="eve_text_highlight">' + data[nowIndex].percent + "%的时间里</tspan>")
          .attr("opacity", "0")
          .transition()
          .duration(300)
          .delay(duration * 0.6 + 120)
          .attr("opacity", "1");
        const currentHtml2 = nowGroup
          .append("text")
          .attr("x", function () {
            if (nowIndex < halfIndex) {
              return xScale(X[nowIndex]) + 8;
            } else {
              return xScale(X[nowIndex]) - 8;
            }
          })
          .attr("y", positionY)
          .attr("dy", "1.1em")
          .attr("class", "eva_current_text")
          .attr("text-anchor", function (d) {
            if (nowIndex < halfIndex) {
              return "start";
            } else {
              return "end";
            }
          })
          .html(`${mode}<=${X[nowIndex]}`)
          .attr("opacity", "0")
          .transition()
          .duration(300)
          .delay(duration * 0.6 + 240)
          .attr("opacity", "1");
      };

      drawAveGroup();
      await sleep(300);
      drawCurrentGroup();
      drawArea();

      $("#" + this.id).html(svg.node());
    },
  },
  mounted() {
    this.init();
  },
};
</script>
