<template>
  <div class="page_title"><span :class="['text', 'hidden', smallText ? 'smallText' : '']" :id="id"></span></div>
</template>
<script>
//特别注意：使用jquery的时候为了避免重名带来的干扰，选择器开头必须是marketChart
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep } from "@/utils/utils.js";

export default {
  data: function () {
    return {
      titleText: null, //动画句柄
      smallText: false,
    };
  },
  props: ["id"],
  methods: {
    async hide() {
      if (this.titleText) {
        this.titleText.runOut();
      }
      await sleep(200);
      $("#" + this.id).addClass("hidden");
    },
    async show(commandData) {
      const title = commandData.pageTitle || commandData.data.pageTitle;
      if (title.length > 20) {
        this.smallText = true
      } else {
        this.smallText = false
      }
      $("#" + this.id).html(title);
      this.$nextTick(async () => {
        $("#" + this.id).removeClass("hidden");
        this.titleText = anyElement({
          targets: document.querySelector("#" + this.id),
          isSplitTxt: true,
          duration: 600,
          delay: 30,
          easing: "easeOutExpo",
          inEffect: "rightInBig",
          outEffect: "leftOutBig",
        });
        await sleep(300);
        this.titleText.runIn();
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "index.less";
</style>
