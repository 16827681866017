<template>
  <div class="pages hidden" id="evaluatingPages">
    <page-title id="evaluatingText" ref="refPageTitle" />

    <!-- <div class="page_date">{{ updateDate }}</div> -->

    <!--估值详情-->
    <eva-summary ref="refEvaluatingSummary" v-if="isEvaSummaryVisible" />

    <!--pe趋势-->
    <div ref="refPeTrendHolder" v-if="isPePbTrendVisible">
      <chart-legend ref="refPeTrendLegend" id="refPeTrendLegend" class="common_legend" />
      <line-chart ref="refPeTrend" id="refPeTrend" customClass="line_chart" />
      <div class="chart_desc" id="chartDesc"></div>
    </div>

    <!--估值分布-->
    <div ref="refEvaluDistChartHolder" v-if="isEvaluDistChartVisible">
      <chart-legend ref="refEvaluDistLegend" id="refEvaluDistLegend" class="common_legend" />
      <dot-dist-chart ref="refEvaluDistChart" id="auctionTable" customClass="evalu_dist_chart" />
      <div :class="{ desc: true, hidden: !isEvaluDistChartDescVisible }">
        注：ROE为负和不合常理的股票不展示，PB>10的股票不展示
      </div>
      <div :class="{ no_data_desc: true, hidden: !isEvaluDistChartDescVisible }">{{ noDataDesc }}</div>
    </div>

    <!--个股估值分布，回测-->
    <div ref="refEvaluatingChartHolder" v-if="isEvaluatingChartVisible">
      <evaluating-chart ref="refEvaluatingChart" id="refEvaluatingChart" customClass="evaluating_chart" />
      <div :class="{ evaluating_desc: true, hidden: !isEvaluatingChartDescVisible }">
        <div class="evaluating_desc_title">{{ evaluatingChartData.descTitle }}</div>
        <div class="evaluating_desc_info">
          <span
            >估值风险 <i :class="evaluatingChartData.risk.riskClass">{{ evaluatingChartData.risk.riskTxt }}</i></span
          >
          <span
            >评估确定性<i :class="evaluatingChartData.riskSure.riskSureClass">{{
              evaluatingChartData.riskSure.riskSureTxt
            }}</i></span
          >
        </div>
        <div class="evaluating_desc_txt">{{ evaluatingChartData.descTxt }}</div>
      </div>

      <div :class="{ evaluating_trace: true, hidden: !isEvaluatingTraceVisible }">
        <div class="trace_desc">{{ evaluatingChartData.traceDesc }}</div>
        <ul class="trace_list">
          <li v-for="(item, index) in evaluatingChartData.trace" :key="index">
            <span v-for="(cell, cIndex) in item" :key="cIndex">{{ cell }}</span>
          </li>
        </ul>
      </div>
    </div>

    <!--行业估值分布-->
    <div ref="refIndustryDistTableHolder" v-if="isIndustryDistTableVisible">
      <table-chart ref="refIndustryDistTable" id="refIndustryDistTable" customClass="industry_dist_table" />
    </div>

    <!--a-->
  </div>
</template>
<script>
//特别注意：使用jquery的时候为了避免重名带来的干扰，选择器开头必须是marketChart
import $ from "jquery";
import anime from "animejs/lib/anime.es.js";
import { anyElement } from "@/components/anyElement.js";
import { sleep, setRem } from "@/utils/utils.js";
import pageTitle from "@/businessComp/charts/pageTitle/index.vue";
import evaSummary from "@/businessComp/charts/evaluatingDistChart/evaSummary.vue";
import chartLegend from "@/components/chartLegend/index.vue";
import lineChart from "@/components/lineChart/index_v1.0.vue";
import tableChart from "@/components/tableChart/index.vue";
import dotDistChart from "@/components/dotDistChart/index_v1.0.vue";
import evaluatingChart from "@/components/evaluatingChart/index_v1.0.vue";

export default {
  data: function () {
    return {
      titleText: null, //动画句柄
      // updateDate: "", //数据更新日期
      isEvaSummaryVisible: false, //估值详情
      isPePbTrendVisible: false, //pe走势图
      isEvaluDistChartVisible: false, //估值分布图
      isEvaluDistChartDescVisible: false, //估值分布图文字
      noDataDesc: "", //估值分布无数据文案
      isEvaluatingChartVisible: false, //个股估值分布
      isEvaluatingChartDescVisible: false, //个股估值分布描述
      evaluatingChartData: {
        //个股估值分布数据
        descTitle: "",
        descTxt: "",
        risk: {
          riskTxt: "",
          riskClass: "",
        },
        riskSure: {
          riskSureTxt: "",
          riskSureClass: "",
        },
        traceDesc: "",
        trace: [],
      },
      isEvaluatingTraceVisible: false, //个股估值回测
      isIndustryDistTableVisible: false, //行业估值分布
    };
  },
  components: {
    pageTitle,
    evaSummary,
    chartLegend,
    lineChart,
    tableChart,
    dotDistChart,
    evaluatingChart,
  },
  methods: {
    preview(commandData) {
      setRem(1080);
      this.show(commandData)
    },
    async hide() {
      return new Promise(async (resolve, reject) => {
        if (this.$refs.refPageTitle) {
          this.$refs.refPageTitle.hide();
        }
        await sleep(200);
        this.isEvaSummaryVisible = false;
        this.isPePbTrendVisible = false;
        this.isEvaluDistChartVisible = false;
        this.isEvaluDistChartDescVisible = false;
        this.isEvaluatingChartVisible = false;
        this.isEvaluatingChartDescVisible = false;
        this.evaluatingChartData = {
          descTitle: "",
          descTxt: "",
          risk: {
            riskTxt: "",
            riskClass: "",
          },
          riskSure: {
            riskSureTxt: "",
            riskSureClass: "",
          },
          traceDesc: "",
          trace: [],
        };
        this.isEvaluatingTraceVisible = false;
        this.isIndustryDistTableVisible = false;
        resolve();
      });
    },
    async show(commandData) {
      $("#evaluatingPages").removeClass("hidden");

      //设置标题
      this.$refs.refPageTitle.show(commandData);
      this.$nextTick(async () => {
        await sleep(100);
        if (commandData.type == "估值详情") {
          this.handleEvaSummary(commandData);
        } else if (["PE-TTM走势图(倍)", "PB走势图(倍)"].indexOf(commandData.type) > -1) {
          this.handlePETrend(commandData);
        } else if (["所在行业估值分布"].indexOf(commandData.type) > -1) {
          this.handleEvaluDistChart(commandData);
        } else if (["个股估值分布", "个股估值回测"].indexOf(commandData.type) > -1) {
          this.handleEvaluatingChart(commandData);
        } else if (["行业估值分布"].indexOf(commandData.type) > -1) {
          this.handleIndustryTable(commandData);
        }
      });
    },
    handleEvaSummary(commandData) {
      this.isEvaSummaryVisible = true;
      this.$nextTick(() => {
        this.renderEvaSummary(commandData);
      });
    },
    renderEvaSummary(commandData) {
      this.$refs.refEvaluatingSummary.show({
        data: commandData.data.data,
        ratio: 1,
        duration: 900,
        delay: 120,
        ease: "easeInOutBack",
      });
    },
    handlePETrend(commandData) {
      this.isPePbTrendVisible = true;
      this.$nextTick(() => {
        this.renderPETrendChart(commandData);
      });
    },
    async renderPETrendChart(commandData) {
      //展示legend
      this.$refs.refPeTrendLegend.createLegend({
        legend: commandData.data.legend,
        legendType: "line",
        colors: ["#FF7700"],
      });

      let width = this.$refs.refPeTrendHolder.clientWidth;
      this.$refs.refPeTrend.LineChart(commandData.data.data, {
        label: (d) => d.label,
        width: width,
        height: width * 0.65,
        marginLeft: 70,
        marginRight: 70,
        marginTop: 20,
        marginBottom: 50,
        duration: 1000,
        delay: 80,
        ease: "easeCubic",
        dotCircleRadius: 0,
        isShowTxtTips: false,
        isFromZero: true,
        yTickNumber: 4,
        xTickNumber: 3,
      });
      $("#evaluatingPages #chartDesc").html(commandData.data.desc);
      this.chartDescHandler = anyElement({
        targets: document.querySelector("#evaluatingPages #chartDesc"),
        isSplitTxt: true,
        duration: 800,
        delay: 40,
        inEffect: "rightIn",
        outEffect: "leftOutBig",
      });
      this.chartDescHandler.runIn();
    },

    handleEvaluDistChart(commandData) {
      this.isEvaluDistChartVisible = true;
      this.$nextTick(async () => {
        this.renderEvaluDistChart(commandData);
        await sleep(300);
        this.isEvaluDistChartDescVisible = true;
      });
    },
    renderEvaluDistChart(commandData) {
      this.$refs.refEvaluDistLegend.createLegend({
        legend: commandData.data.legend,
        legendType: ["dot", "dot", "line"],
        colors: ["#FF7700", "#1B9EF6", "#888"],
      });
      let width = this.$refs.refEvaluDistChartHolder.clientWidth;
      this.$refs.refEvaluDistChart.DotDistChart(commandData.data.data.dataArr, {
        label: (d) => d.label,
        dot: (d) => d.dot,
        lineOrigin: commandData.data.data.lineOrigin,
        lineSlope: commandData.data.data.lineSlope,
        width: width,
        height: width * 0.63,
        marginLeft: 90,
        marginRight: 90,
        marginTop: 80,
        marginBottom: 40,
        xFormat: (d) => d + "%",
        xLabel: "(ROE)",
        yLabel: "(PB)",
        duration: 900,
        delay: 30,
        ease: "easeExpInOut",
        dotCircleRadius: 6,
        tipWidth: 360,
        tipHeight: 160,
      });
      this.noDataDesc = commandData.data.noDataDesc;
    },

    //个股估值分布
    handleEvaluatingChart(commandData) {
      this.isEvaluatingChartVisible = true;
      this.$nextTick(() => {
        this.renderEvaluatingChart(commandData);
      });
    },
    async renderEvaluatingChart(commandData) {
      let width = this.$refs.refEvaluatingChartHolder.clientWidth;
      this.$refs.refEvaluatingChart.EvaluatingChart(commandData.data.data, {
        width: width,
        height: width * 0.56,
        marginTop: 73,
        marginBottom: 41,
        marginLeft: 90,
        marginRight: 40,
        duration: 700,
        delay: 30,
        ease: "easeCubicOut",
        rectWidth: 240,
        rectHeight: 50,
      });
      await sleep(400);
      this.evaluatingChartData = { ...commandData.data };
      if (commandData.type == "个股估值分布") {
        this.isEvaluatingChartDescVisible = true;
      } else if (commandData.type == "个股估值回测") {
        this.isEvaluatingTraceVisible = true;
      }
    },
    //个股估值分布
    handleIndustryTable(commandData) {
      this.isIndustryDistTableVisible = true;
      this.$nextTick(() => {
        this.renderIndustryTable(commandData);
      });
    },
    async renderIndustryTable(commandData) {
      let width = this.$refs.refIndustryDistTableHolder.clientWidth;
      this.$refs.refIndustryDistTable.TableChart(commandData.data.data, {
        width: width,
        height: width * 0.78,
        duration: 600,
        delay: 40,
        ease: "easeOutCubic",
        titleRows: [1],
        highlightRows: [commandData.data.highlightIndex + 2],
      });
    },
  },

  async mounted() {
    window.hide = this.hide;
    window.show = this.preview;
    this.$bus.$on("chartExit", () => {
      this.hide();
    });
  },
};
</script>
<style lang="less" scoped>
@import "index.less";
</style>
