<template>
  <div class="eva_summary">
    <div class="circle">
      <div class="circle_inside1">
        <div class="circle_inside2">
          <span>总市值</span><b>{{ summaryList.total }}</b>
        </div>
      </div>
    </div>
    <div class="circle">
      <div class="circle_inside1">
        <div class="circle_inside2">
          <span>PE-TTM</span><b>{{ summaryList.pe }}</b>
        </div>
      </div>
    </div>
    <div class="circle">
      <div class="circle_inside1">
        <div class="circle_inside2">
          <span>PB</span><b>{{ summaryList.pb }}</b>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//特别注意：使用jquery的时候为了避免重名带来的干扰，选择器开头必须是marketChart
import $ from "jquery";
import anime from "animejs/lib/anime.es.js";
import { anyElement } from "@/components/anyElement.js";
import { sleep, setRem } from "@/utils/utils.js";

export default {
  data: function () {
    return {
      summaryList: {
        total: 0,
        pe: 0,
        pb: 0,
      },
    };
  },
  methods: {
    async hide() {},
    async show(settings) {
      this.summaryList = { ...settings.data };

      this.circleHandler = anime({
        targets: document.querySelectorAll(".eva_summary .circle"),
        duration: settings.duration,
				delay: anime.stagger(settings.delay, { from: this.from }),
        opacity: [0, 1],
        margin: [150, 0],
        easing: settings.ease,
        autoplay: true,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.eva_summary {
  padding-top: 70%;
  position: relative;
  .circle {
    position: absolute;
    width: 30%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    .circle_inside1 {
      padding-top: 100%;
      position: relative;
    }
    .circle_inside2 {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 44px;
      text-align: center;
      color: #fff;
      span {
        display: block;
				font-size: 48px;
        white-space: nowrap;
      }
      b {
        display: block;
        white-space: nowrap;
        font-weight: bold;
      }
    }
  }
  .circle:nth-child(1) {
    position: absolute;
    left: 31%;
    top: 30%;
    background: #ff3b30;
  }
  .circle:nth-child(2) {
    left: 68%;
    top: 30%;
    background: #3c88d4;
  }
  .circle:nth-child(3) {
    left: 50%;
    top: 78%;
    background: #ff7700;
  }
}
</style>
